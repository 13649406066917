import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import INaturalistObservations from '../components/INaturalistObservations'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <h1
          className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
          style={{
            boxShadow:
              'rgb(0, 100, 0) 0.5rem 0px 0px, rgb(0, 100, 0) -0.5rem 0px 0px',
            backgroundColor: 'rgb(0, 100, 0)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {title}
        </h1>
        <h3
          className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow:
              'rgb(0, 100, 0) 0.5rem 0px 0px, rgb(0, 100, 0) -0.5rem 0px 0px',
            backgroundColor: 'rgb(0, 100, 0)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        >
          {subheading}
        </h3>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="tile">
                    <h1 className="title">{mainpitch.title}</h1>
                  </div>
                  <div className="tile">
                    <h3 className="subtitle">{mainpitch.description}</h3>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Thicket of Diversity on iNaturalist.org
                    </h3>
                    <INaturalistObservations/>
                    <h3 className="has-text-weight-semibold is-size-2">
                      Recent Observations on iNaturalist:
                    </h3>
                    <table data-current-page="1" data-per-page="5" data-total-entries="642" data-total-pages="129">
                      <tr class="inat-observation">
                        <td class="inat-observation-image" valign="top" align="center">
                          <a href="https://www.inaturalist.org/observations/92142666">
                            <img border="0" title="butterfly pea from Warren, TX 77664, USA on August 22, 2021 at 12:26 PM by Jace Stansbury" src="https://static.inaturalist.org/photos/152516823/square.jpeg?1629658752" /></a></td>
                        <td class="inat-observation-body" valign="top">
                          <a href="https://www.inaturalist.org/observations/92142666">butterfly pea</a>
                          <div class="inat-meta">
                            <span class="inat-label">Observer: </span>
                            <span class="inat-value">
                              <a href="https://www.inaturalist.org/observations/jacestansbury">jacestansbury</a>
                            </span>
                            <br/>
                            <span class="inat-label">Date: </span>
                            <span class="inat-value"> Aug 22 2021</span><br/>
                            <span class="inat-label">Place: </span>
                            <span class="inat-value">Warren, TX 77664, USA</span>
                          </div>
                        </td>
                      </tr>
                      <tr class="inat-observation">
                        <td class="inat-observation-image" valign="top" align="center"><a href="https://www.inaturalist.org/observations/92115775"><img border="0" title="Six-lined Racerunner from Warren, TX 77664, USA on August 22, 2021 at 09:19 AM by Jace Stansbury" src="https://static.inaturalist.org/photos/152516632/square.jpeg?1629658707" /></a></td>
                        <td class="inat-observation-body" valign="top"><a href="https://www.inaturalist.org/observations/92115775">Six-lined Racerunner</a><div class="inat-meta"><span class="inat-label">Observer: </span><span class="inat-value"><a href="https://www.inaturalist.org/observations/jacestansbury">jacestansbury</a></span><br/><span class="inat-label">Date: </span><span class="inat-value"> Aug 22 2021</span><br/><span class="inat-label">Place: </span><span class="inat-value">Warren, TX 77664, USA</span></div></td>
                      </tr>
                      <tr class="inat-observation">
                        <td class="inat-observation-image" valign="top" align="center"><a href="https://www.inaturalist.org/observations/88475690"><img border="0" title="Black-shouldered Spinyleg from Warren, TX 77664, USA on July 23, 2021 at 11:10 AM by Jace Stansbury" src="https://static.inaturalist.org/photos/145981331/square.jpeg?1627174509" /></a></td>
                        <td class="inat-observation-body" valign="top"><a href="https://www.inaturalist.org/observations/88475690">Black-shouldered Spinyleg</a><div class="inat-meta"><span class="inat-label">Observer: </span><span class="inat-value"><a href="https://www.inaturalist.org/observations/jacestansbury">jacestansbury</a></span><br/><span class="inat-label">Date: </span><span class="inat-value"> Jul 23 2021</span><br/><span class="inat-label">Place: </span><span class="inat-value">Warren, TX 77664, USA</span></div></td>
                      </tr>
                      <tr class="inat-observation">
                        <td class="inat-observation-image" valign="top" align="center"><a href="https://www.inaturalist.org/observations/88476052"><img border="0" title="Eastern Amberwing from Warren, TX 77664, USA on July 23, 2021 at 11:10 AM by Jace Stansbury" src="https://static.inaturalist.org/photos/145982225/square.jpeg?1627174758" /></a></td>
                        <td class="inat-observation-body" valign="top"><a href="https://www.inaturalist.org/observations/88476052">Eastern Amberwing</a><div class="inat-meta"><span class="inat-label">Observer: </span><span class="inat-value"><a href="https://www.inaturalist.org/observations/jacestansbury">jacestansbury</a></span><br/><span class="inat-label">Date: </span><span class="inat-value"> Jul 23 2021</span><br/><span class="inat-label">Place: </span><span class="inat-value">Warren, TX 77664, USA</span></div></td>
                      </tr>
                      <tr class="inat-observation">
                        <td class="inat-observation-image" valign="top" align="center"><a href="https://www.inaturalist.org/observations/88267969"><img border="0" title="spotted horse mint from Warren, TX 77664, USA on July 23, 2021 at 10:41 AM by Jace Stansbury" src="https://static.inaturalist.org/photos/145615739/square.jpeg?1627055583" /></a></td>
                        <td class="inat-observation-body" valign="top"><a href="https://www.inaturalist.org/observations/88267969">spotted horse mint</a><div class="inat-meta"><span class="inat-label">Observer: </span><span class="inat-value"><a href="https://www.inaturalist.org/observations/jacestansbury">jacestansbury</a></span><br/><span class="inat-label">Date: </span><span class="inat-value"> Jul 23 2021</span><br/><span class="inat-label">Place: </span><span class="inat-value">Warren, TX 77664, USA</span></div></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
