import React, { Component } from 'react'


class INaturalistObservations extends Component {
  
  render() {

    return (
      <div class="inat-widget">
        <div class="inat-widget-header">
          <a href="https://www.inaturalist.org"><img alt="iNaturalist" src="https://www.inaturalist.org/assets/logo-small-0952e30acb89d43b4ac3550901b929325ffc6bfcbff8a358825dbc14e04626f4.gif" /></a>  
        </div>
        <table class="inat-footer">
          <tr class="inat-user">
            <td class="inat-value">
              <strong>
                  <a rel="nofollow" href="https://www.inaturalist.org/observations?project_id=1409">View the observations project for the Big Thicket on <nobr>iNaturalist »</nobr></a>
              </strong>
            </td>
          </tr>
        </table>
      </div>
    )
  }
}
export default INaturalistObservations


